import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../../../../utils/forms";
import {ChevronLeft} from "@material-ui/icons";
import {OutcomeModel} from "./outcome.model";
import {UserContext} from "../../../../../context/usercontext";


const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: absolute;
    top: 15vh;
    height: 65vh;
  }
`;

const mapUsersToDropDownItems = (users = []) =>
  users.map(({ _id, firstName, lastName }) => ({
    _id,
    name: `${firstName ?? ''} ${lastName ?? ''}`.trim(),
  }));

export const CreateOutcomeDialog = ({open, outcome, onClose, onCreate, users = []}) => {
  const [model, setModel] = useState(JSON.parse(JSON.stringify(OutcomeModel)));
  const { user } = useContext(UserContext)

  useEffect(() => {
    if (open && users) {
      const obj = (JSON.parse(JSON.stringify(OutcomeModel)));
      obj.measuredBy.dropDownItems = mapUsersToDropDownItems(users);
      obj.measuredBy.value = user._id;
      setModel(obj);
    }
    // eslint-disable-next-line
  }, [open, users]);

  const handleChange = (form, fieldName) => {
    setModel({...model, ...form})
  }

  const handleSave = () => {
    let data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });
    onCreate(data)
  }

  return (
    <StyledDialog
      open={open}
      fullWidth={true}
      maxWidth={"xl"}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>
        <Button size={"small"} startIcon={<ChevronLeft />} onClick={onClose} /> Outcome
      </DialogTitle>
      <DialogContent dividers>
        {JSON.stringify(outcome, null, 2)}
        <FormFieldsGenerator model={model} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          style={{ marginRight: "8px" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!isModelValid(model)}
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  )

}
