import React from 'react';
import {Box, TextField} from "@material-ui/core";
import moment from "moment/moment";

export const CreateCareplanDashboard = ({ model }) => {

  return (
    <Box p={1}
         display={"flex"}
         justifyContent={"space-between"}
         width={"100%"}>
      <TextField label={"Care Plan Name"}
                 underline={"none"}
                 InputLabelProps={{shrink: true}}
                 InputProps={{readOnly: true, disableUnderline: true }}
                 value={model.name.value} />
      <TextField label={"Care Plan Status"}
                 InputLabelProps={{shrink: true}}
                 InputProps={{readOnly: true, disableUnderline: true }}
                 value={model.status.value}
      />
      <TextField label={"Problem Area"}
                 InputLabelProps={{shrink: true}}
                 InputProps={{readOnly: true, disableUnderline: true }}
                 value={model.problemArea.value}
      />
      <TextField label={"Care Plan Type"}
                 InputLabelProps={{shrink: true}}
                 InputProps={{readOnly: true, disableUnderline: true }}
                 value={model.planType.value}
      />
      <TextField label={"Careplan Date"}
                 InputLabelProps={{shrink: true}}
                 InputProps={{readOnly: true, disableUnderline: true }}
                 value={model.careplanDate.value ? moment(model.careplanDate.value).format("MM/DD/YYYY"): null}
      />
      <TextField label={"Discontinued Date"}
                 InputLabelProps={{shrink: true}}
                 InputProps={{readOnly: true, disableUnderline: true }}
                 value={model.discontinuedDate ? moment(model.discontinuedDate.value).format("MM/DD/YYYY") : null}
      />
      <TextField label={"Review Date"}
                 InputLabelProps={{shrink: true}}
                 InputProps={{readOnly: true, disableUnderline: true }}
                 value={model.reviewDate.value ? moment(model.reviewDate.value).format("MM/DD/YYYY"): null}
      />
    </Box>
  )
}
