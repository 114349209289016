import React from 'react';
import {Paper} from "@material-ui/core";
import {CreateCareplan} from "../../../components/resident/care_plan/create/CreateCareplan";
import styled from "styled-components";
import {useParams} from "react-router-dom";

const CareplanPaper = styled(Paper)`
    padding: .5rem;
`;

export const CreateCareplanPage = () => {
  const { residentId } = useParams();

    return (
      <CareplanPaper elevation={24}>
        <CreateCareplan residentId={residentId} />
      </CareplanPaper>
    )
}
