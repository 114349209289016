import React from 'react';
import {
  Badge,
  Button, Chip,
  Divider,
  Grid,
  List,
  ListItem, ListItemIcon, ListItemText,
  makeStyles,
  Paper,
  TablePagination, TextField,
  Typography
} from "@material-ui/core";
import {blue, green, grey, orange, red, yellow} from "@material-ui/core/colors";
import moment from "moment";
import {
  AddCommentTwoTone,
  AttachmentTwoTone,
  CommentTwoTone, WarningRounded,
} from "@material-ui/icons";
import {UserContext} from "../../context/usercontext";
import {getTenantLocationResidentNotes} from "../../services/tenant.service";
import {Link} from "react-router-dom";
import CreateNoteDialog from "./CreateNoteDialog";
import {RetryMessage} from "../notification/retry.message";
import {ListLoading} from "../../utils/indicators/list.loading";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const contrast = 400;
// const height = '31.33rem';
const height = '26rem';

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    padding: theme.spacing(1),
  },
  avatarContainer: {
    marginRight: theme.spacing(3),
  },
  avatarSmall: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  avatarLarge: {
    background: theme.palette.primary,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  scheduled: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: grey[500],
  },
  readyToPass: {
    background: grey[700],
  },
  given: {
    background: green[contrast],
  },
  late: {
    background: orange[contrast],
  },
  missed: {
    background: red[contrast],
  },
  hold: {
    background: yellow[contrast],
  },
  sa: {
    background: blue[contrast],
  },
  na: {
    background: grey[contrast],
  },
  refused: {
    background: red[contrast],
  },
  destruction: {
    background: yellow[contrast],
  },
  appBar: {},
  maxHeight: {
    height: '35rem',
    overflow: 'scroll',
  },
  paginationContainer: {
    alignItems: 'center',
  },
  typeContainer: {
    padding: theme.spacing(0, 1),
  },
  contentContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    minHeight: height,
    maxHeight: height,
    overflowY: 'scroll'
  },
  errorContent: {
    padding: theme.spacing(5),
    minHeight: height,
    maxHeight: height,
    overflowY: 'scroll'
  },
  retryBtn: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-end"
  },
  errorLogo: {
    color: theme.palette.warning.dark,
    fontSize: '2rem'
  },
  noteContainer: {
    width: '100%',
  },
  noteTitle: {},
  noteContent: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.grey[400],
    padding: theme.spacing(0, 1)
  },
  notesContainer: {
    margin: theme.spacing(1, 0)
  },
  badgeContainer: {
    padding: theme.spacing(1)
  },
  userInfo: {
    padding: theme.spacing(1),
    textAlign: 'right'
  },
  lockedChip: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText
  }
}));


const ResidentNotes = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [notes, setNotes] = React.useState([]);
  const { selectedTenant, selectedTenantLocation} = React.useContext(UserContext);
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(25);
  const [sort] = React.useState('-createdAt');
  const [total, setTotal] = React.useState(0);
  const [filter, setFilter] = React.useState();
  const [startDate, setStartDate] = React.useState(moment().subtract(1, 'day'));
  const [endDate, setEndDate] = React.useState(moment().add(1, 'day'));
  const [openCreateNoteDialog, setOpenCreateNoteDialog] = React.useState(false);

  React.useEffect(() => {
    if (selectedTenant && selectedTenantLocation) {
      getData(page, size, filter, startDate, endDate);
    }
    // eslint-disable-next-line
  },[selectedTenant, selectedTenantLocation]);

  const getData = async (pg, sz, filt, start, end) => {
    setLoading(true);
    setError(false);
    const response =await getTenantLocationResidentNotes(
      selectedTenant?._id,
      selectedTenantLocation?._id,
      pg,
      sz,
      sort,
      filt,
      start,
      end
    );
    if (response?.error) {
      setError(true);
      setNotes([])
    } else {
      setError(false);
      setTotal(response?.paginator?.totalItems);
      setPage(response?.paginator?.currentPage);
      setSize(response?.paginator?.perPage);
      setNotes(response?.content);
    }
    setLoading(false);
  }

  const onFilterChange = async e => {
    setFilter(e.target.value);
    if (e.target.value.length > 2) {
      await getData(page, size, e.target.value, startDate, endDate);
    } else if (e.target.value.length === 0) {
      await getData(page, size, null, startDate, endDate);
    }
  }

  const retry = async () => {
    await getData(page, size, filter);
  }

  const handleChangePage = async (event, pg) => {
    setPage(pg + 1);
    await getData(pg + 1, size, filter, startDate, endDate);
  };

  const onChangeRowsPerPage = async (event) => {
    setSize(event.target.value);
    await getData(page, event.target.value, filter, startDate, endDate);
  };

  const saveNote = note => {
    const obj = [note, ...notes]
    setNotes(obj);
    setOpenCreateNoteDialog(false);
  }

  const onDateChange = async (date, field) => {
    if (field === 'start') {
      setStartDate(date);
      if (date.isValid()) {
        await getData(page, size, filter, date, endDate)
      }
    } else {
      setEndDate(date);
      if (date.isValid()) {
        await getData(page, size, filter, startDate, date)
      }
    }
  }

  return (
    <Paper elevation={24}>
      <Grid container
            className={classes.header}
            justifyContent={"space-between"}
            alignItems={"center"}>
        <Typography variant="h6">
          Resident Notes
        </Typography>
        <Button color={"primary"}
                startIcon={<AddCommentTwoTone />}
                variant={"contained"}
                onClick={() => setOpenCreateNoteDialog(true)}
        >
          Add Note
        </Button>
      </Grid>
      <Divider />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container justifyContent={"space-between"} style={{padding: '.25rem'}}>
          <Grid item xs={12} sm={6}>
            <KeyboardDateTimePicker
              fullWidth
              size="small"
              name={"start"}
              required={true}
              value={startDate}
              label={"Start"}
              format="MM/DD/YYYY"
              onChange={(date) => onDateChange(date, "start")}
              allowKeyboardControl="true"
            />

          </Grid>
          <Grid item xs={12} sm={6}>
            <KeyboardDateTimePicker
              fullWidth
              size="small"
              name={"end"}
              required={true}
              value={endDate}
              label={"End"}
              format="MM/DD/YYYY"
              onChange={(date) => onDateChange(date, "end")}
              allowKeyboardControl="true"
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>

      <Divider />
      <TablePagination component={"div"}
                       rowsPerPageOptions={[ 1, 5, 10, 25, 50]}
                       page={page - 1}
                       rowsPerPage={size}
                       count={total}
                       onRowsPerPageChange={onChangeRowsPerPage}
                       onPageChange={handleChangePage}
      />
      <Divider />
      <Grid container
            className={classes.header}
            justifyContent={"space-between"}
            alignItems={"center"}>
        <TextField variant={"outlined"}
                   fullWidth
                   onChange={onFilterChange}
                   value={filter}
                   placeholder={"Search"} />

      </Grid>
      {loading && <ListLoading rows={5} />}
      {error && <RetryMessage message={"Unable to load resident notes"} onRetry={retry} />}
      {!loading && !error && notes?.length === 0 &&
      <div >
        <Divider />
        <div >
          <List>
            <ListItem>
              <ListItemIcon>
                <WarningRounded />
              </ListItemIcon>
              <ListItemText primary={"No notes for this time frame"}
                            secondary={`${moment(startDate).format('MM/DD/YYYY')} -
                            ${moment(endDate).format('MM/DD/YYYY')}
                            `}
              />
            </ListItem>
          </List>
        </div>
      </div>
      }
      {!loading && !error && notes &&
        <div className={classes.notesContainer}>
          <Divider />
          <div className={classes.contentContainer}>
            <List>
              {notes.map(note =>
              <ListItem button
                        component={Link} to={`/residents/${note?.resident?._id}/notes/${note?._id}`}>
                <div className={classes.noteContainer}>
                  <Grid container
                        justify={"space-between"}
                        className={classes.noteTitle}>
                    <div>
                      <Typography variant={"subtitle1"}>{note?.resident?.firstName} {note?.resident?.lastName}</Typography>
                    </div>
                    <div>
                      <Typography variant={"subtitle1"}>{moment(note.noteDate).format('MM/DD/YYYY hh:mm a')}</Typography>
                    </div>
                  </Grid>
                  <Grid container
                        justify={"space-between"}>
                    <Chip size={"small"}
                          color={"primary"}
                          style={{marginBottom: '.5rem'}}
                          label={note?.category} />
                    {note?.locked && <Chip size={"small"}
                          className={classes.lockedChip}
                          label={"Locked"}
                    />}
                  </Grid>
                  <Divider />
                  <Grid container
                        className={classes.badgeContainer}
                        justify={"flex-end"}
                  >
                    {note?.comments?.length > 0 &&
                    <Badge badgeContent={note?.comments?.length} color="secondary" style={{marginRight: '.5rem'}}>
                      <CommentTwoTone />
                    </Badge>}
                    {note?.files?.length > 0 &&
                    <Badge badgeContent={note?.files?.length} color="secondary">
                      <AttachmentTwoTone />
                    </Badge>}
                  </Grid>
                  <div className={classes.noteContent}>
                    <Typography variant={"subtitle2"}>{note?.subject}</Typography>
                    <div dangerouslySetInnerHTML={{__html: note?.html}} />
                  </div>
                  <div className={classes.userInfo}>
                    <Typography variant={"subtitle2"}>Created By: {note?.user?.firstName} {note?.user?.lastName}</Typography>
                  </div>
                </div>

              </ListItem>
              )}
            </List>
          </div>
        </div>
      }
      <CreateNoteDialog open={openCreateNoteDialog}
                        onSave={saveNote}
                        onClose={() => setOpenCreateNoteDialog(false)} />
    </Paper>
  )
}

export default ResidentNotes;
