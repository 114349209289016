import React from 'react';
import {UserContext} from "./usercontext";
import {getTenantLocationResidenAlertsForType, getTenantLocationResidentAlerts} from "../services/tenant.service";
import * as _ from "lodash";

let TenantLocationAlertContext = React.createContext();

let initialState = {
  loading: false,
  loadingAlertTypes: false,
  error: false,
  currentCount: 0,
  lateCount: 0,
  missedCount: 0,
  type: null,
  loadingAlerts: false,
  loadingAlertsError: false,
  showAlertsDialog: false,
  alerts: [],
  page: 1,
  size: 25
}

const TenantLocationAlertContextActions = {
  GET_ALERT_COUNT_START: "GET_ALERT_COUNT_START",
  GET_ALERT_COUNT_COMPLETE: "GET_ALERT_COUNT_COMPLETE",
  GET_ALERT_COUNT_ERROR: "GET_ALERT_COUNT_ERROR",
  REFRESH_ALERT_COUNT: "REFRESH_ALERT_COUNT",
  SHOW_ALERTS_DIALOG: "SHOW_ALERTS_DIALOG",
  CLOSE_ALERTS_DIALOG: "CLOSE_ALERTS_DIALOG",
  SET_DIALOG_ALERTS: "SET_DIALOG_ALERTS",
  SET_DIALOG_ALERTS_ERROR: "SET_DIALOG_ALERTS_ERROR",
  TOGGLE_ALERT: "TOGGLE_ALERT",
  TOGGLE_LOADING: "TOGGLE_LOADING",
  TOGGLE_LOADING_ALERT_TYPES: "TOGGLE_LOADING_ALERT_TYPES"
}

let reducer = (state, action) => {
  switch (action.type) {
    case TenantLocationAlertContextActions.TOGGLE_LOADING_ALERT_TYPES:
      return {...state, loadingAlertTypes: action?.payload };
    case TenantLocationAlertContextActions.TOGGLE_LOADING:
      return {...state, loading: action?.payload };
    case TenantLocationAlertContextActions.GET_ALERT_COUNT_START:
      return {...state, loading: true, error: false, currentCount: 0, lateCount: 0, missedCount: 0 };
    case TenantLocationAlertContextActions.GET_ALERT_COUNT_COMPLETE:
      return {
        ...state,
        loading: false,
        error: false,
        currentCount: action?.payload.currentCount,
        lateCount: action?.payload.lateCount,
        missedCount: action?.payload.missedCount,
        prnRecheckCount: action?.payload?.prnRecheckCount
      };
    case TenantLocationAlertContextActions.GET_ALERT_COUNT_ERROR:
      return {...state, loading: false, error: true, currentCount: 0, lateCount: 0, missedCount: 0 };
    case TenantLocationAlertContextActions.SHOW_ALERTS_DIALOG:
      return {...state, showAlertsDialog: true, loadingAlerts: true, loadingAlertsError: false, type: action?.payload };
    case TenantLocationAlertContextActions.CLOSE_ALERTS_DIALOG:
      return {...state, showAlertsDialog: false };
    case TenantLocationAlertContextActions.SET_DIALOG_ALERTS:
      return {...state,
        loadingAlertsError: false,
        alerts: action?.payload.alerts,
        page: action?.payload?.page,
        size: action?.payload?.size,
        totalItems: action?.payload?.totalItems
      };
    case TenantLocationAlertContextActions.SET_DIALOG_ALERTS_ERROR:
      return {...state, loadingAlerts: false, loadingAlertsError: true };
    case TenantLocationAlertContextActions.TOGGLE_ALERT: {
      const alerts = state?.alerts?.map(a => {
        if (a?._id === action?.payload?._id) {
          a.dismissed = action?.payload?.dismissed;
        }
        return a;
      });
      return {...state, alerts };
    }
    default:
      return {...state};
  }

}

const TenantLocationAlertContextProvider = ({ children }) => {
  let [state, dispatch] = React.useReducer(reducer, initialState);
  const { selectedTenant, selectedTenantLocation } = React.useContext(UserContext);

  const getData = async () => {
    dispatch({type: TenantLocationAlertContextActions.GET_ALERT_COUNT_START});
    const response = await getTenantLocationResidentAlerts(selectedTenant?._id, selectedTenantLocation?._id);
    if (!response?.error) {
      parseResponse(response);
    } else {
      dispatch({type: TenantLocationAlertContextActions.GET_ALERT_COUNT_ERROR });
    }
  }

  const getAlertsForType = async (type, page = 1, size = 10) => {
    dispatch({type: TenantLocationAlertContextActions.TOGGLE_LOADING_ALERT_TYPES, payload: true })
    const response = await getTenantLocationResidenAlertsForType(
      selectedTenant?._id,
      selectedTenantLocation?._id,
      type,
      page,
      size);
    if (response?.error) {
      dispatch({type: TenantLocationAlertContextActions.SET_DIALOG_ALERTS_ERROR });
    } else {
      const { currentPage, perPage, totalItems } = response?.paginator;
      const payload = {
        alerts: response?.content,
        page: currentPage,
        size:  perPage,
        totalItems: totalItems
      }
      dispatch({ type: TenantLocationAlertContextActions.SET_DIALOG_ALERTS, payload });
    }
    dispatch({type: TenantLocationAlertContextActions.TOGGLE_LOADING_ALERT_TYPES, payload: false })
  }

  const toggleAlert = async alert => {
    dispatch({type: TenantLocationAlertContextActions.TOGGLE_ALERT, payload: alert });
    await getData();
  }

  let value = { state, dispatch, tenantLocationAlertCountLoading: state.loading, getTenantLocationAlerts: getData, getAlertsForType, toggleAlert };

  React.useEffect(() => {
    if (selectedTenant && selectedTenantLocation) {
      getData();
    }
    // eslint-disable-next-line
  },[selectedTenant, selectedTenantLocation]);

  const parseResponse = response => {
    const currentAlerts = response.filter(r => r?.type?.indexOf('current') > -1);
    const currentCount = _.sumBy(currentAlerts, item => item.count);
    const lateAlerts = response.filter(r => r?.type?.indexOf('late') > -1);
    const lateCount = _.sumBy(lateAlerts, item => item.count);
    const missedAlerts = response.filter(r => r?.type?.indexOf('missed') > -1);
    const missedCount = _.sumBy(missedAlerts, item => item.count);
    const prnRecheckAlerts = response?.filter(r => r?.type === 'prn_recheck_medication');
    const prnRecheckCount = _.sumBy(prnRecheckAlerts, item => item.count);
    dispatch(
      {
        type: TenantLocationAlertContextActions.GET_ALERT_COUNT_COMPLETE,
        payload: { currentCount, lateCount, missedCount, prnRecheckCount }
      });
  }

  return (
    <TenantLocationAlertContext.Provider value={value}>{children}</TenantLocationAlertContext.Provider>
  )

}

let TenantLocationAlertContextConsumer = TenantLocationAlertContext.Consumer;

export {
  TenantLocationAlertContext,
  TenantLocationAlertContextProvider,
  TenantLocationAlertContextConsumer,
  TenantLocationAlertContextActions
};
