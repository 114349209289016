import moment from "moment/moment";

export const OutcomeModel = {
  _id: {
    hidden: true,
  },
  description: {
    title: 'Description',
    required: true,
    value: null,
    dirty: false,
    isValid: false,
    checkbox: false,
    multiline: true,
    multilineRows: 3
  },
  status: {
    title: 'Status',
    required: true,
    value: "Not Started",
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [{
      name: 'Not Started',
    }, {
      name: 'In Progress',
    }, {
      name: 'Completed',
    }],
    dropDownItemValue: 'name',
    dropDownItemLabel: 'name',
    date: false,
  },
  measuredAt: {
    title: 'Measured At',
    required: true,
    value: Date.now(),
    dirty: false,
    isValid: false,
    checkbox: false,
    date: true,
    maxDate: true,
    maxDateValue: moment(Date.now())
  },
  measuredBy: {
    title: 'Measured By',
    required: false,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: true,
    dropDownItems: [],
    dropDownItemValue: '_id',
    dropDownItemLabel: 'name',
    date: false,
  },
  notes: {
    title: 'Notes',
    required: false,
    value: null,
    dirty: false,
    isValid: false,
    multiline: true,
    multilineRows: 5,
  },
};
