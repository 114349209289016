import React, {useState} from "react";
import {
  Dialog,
  Backdrop,
  Button,
  Box, DialogContent, DialogActions, DialogTitle, Tabs, Tab, Grid, Hidden,
} from "@material-ui/core";
import moment from "moment";
import {FormFieldsGenerator, isModelValid} from "../../../../../utils/forms";
import styled from "styled-components";
import {Details, Equalizer} from "@material-ui/icons";
import {CareplanOutcomes} from "../../outcomes";
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteBtn} from "../../../../utils/buttons/DeleteBtn";

const STATUS_ENUM = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
};

// Initial Form Model
const getInitialFormModel = (data) => ({
  _id: {
    hidden: true,
    value: data?._id,
    required: true,
  },
  name: {
    title: "Name",
    value: data?.name,
    required: true,
    isValid: true,
    textType: "text",
  },
  description: {
    title: "Description",
    value: data?.description,
    required: false,
    multiline: true,
    multilineRows: 3,
  },
  startDate: {
    title: "Start Date",
    value: moment(data?.startDate).format("YYYY-MM-DD"),
    required: true,
    date: true,
  },
  targetDate: {
    title: "Target Date",
    value: moment(data?.targetDate).format("YYYY-MM-DD"),
    required: true,
    date: true,
  },
  status: {
    title: "Status",
    value: data?.status,
    dropDown: true,
    dropDownItems: Object.values(STATUS_ENUM).map((status) => ({
      label: status,
      value: status,
    })),
    dropDownItemValue: "value",
    dropDownItemLabel: "label",
    required: false,
  },
  isActive: {
    title: "Is Active",
    value: data?.isActive,
    isToggle: true,
    required: false,
    hidden: true,
  },
});

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: absolute;
    top: 15vh;
    height: 65vh;
  }
`;

export const EditGoalModal = ({ careplan, isOpen, goal, onClose, onSave, onDelete, users = [] }) => {
  const [formModel, setFormModel] = useState(getInitialFormModel());
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  }

  const handleFormChange = (updatedModel, changedKey) => {
    setFormModel(updatedModel);
  };

  // Reset the form model each time the modal is opened
  React.useEffect(() => {
    if (isOpen && goal) {
      setTabValue(0)
      setFormModel(getInitialFormModel(goal));
    }
  }, [isOpen, goal]);

  const handleSubmit = () => {
    const data =
      Object.keys(formModel).reduce((acc, key) => {
        acc[key] = formModel[key].value;
        if (key === "startDate" || key === "targetDate") {
          acc[key] = moment(formModel[key].value).toISOString();
        }
        return acc;
      }, {})
    onSave(data)
  };

  const handleDelete = () => {
    onDelete(goal)
  }

  const handleOutcomeAdded = outcome => {
    goal.outcomes = [...goal.outcomes, outcome]
  }

  const handleOutcomeRemoved = outcome => {
    goal.outcomes = goal.outcomes.filter(o => o._id !== outcome._id)
  }

  return (
    <StyledDialog
      open={isOpen}
      fullWidth={true}
      maxWidth={"xl"}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>
        Edit Goal
      </DialogTitle>
      <DialogContent dividers>
        <Hidden xsDown>
          <Grid container justifyContent={"space-between"} style={{width: "100%"}}>
            <Grid item xs={12} md={8}>
              <Box p={5}>
                <FormFieldsGenerator model={formModel} onChange={handleFormChange} />
                <Box display="flex" justifyContent="flex-end" pt={3}>
                  <Button
                    variant="text"
                    onClick={onClose}
                    style={{ marginRight: "8px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isModelValid(formModel)}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box p={5}>
                <CareplanOutcomes goal={goal}
                                  users={users}
                                  careplan={careplan}
                                  onAdd={handleOutcomeAdded}
                                  onRemove={handleOutcomeRemoved}
                />
              </Box>
            </Grid>
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Tabs value={tabValue}
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable force tabs example"
                centered
                onChange={handleTabChange}>
            <Tab label="Details" icon={<Details />} />
            <Tab label="Outcomes" icon={<Equalizer />} />
          </Tabs>
          {tabValue === 0 &&
            <>
              <FormFieldsGenerator model={formModel} onChange={handleFormChange} />
            </>
          }
          {tabValue === 1 &&
            <div>
              <CareplanOutcomes goal={goal}
                                users={users}
                                careplan={careplan}
                                onAdd={handleOutcomeAdded}
                                onRemove={handleOutcomeRemoved}
              />
            </div>
          }
        </Hidden>
      </DialogContent>
      <DialogActions>
        <Box display={"flex"} justifyContent={"space-between"} style={{width: "100%"}}>
          <DeleteBtn buttonText={"Delete Goal"} onClick={handleDelete} icon={<DeleteIcon />} color={"secondary"} size={"small"} />
          <Button size={"small"}
                  onClick={onClose}
          >
            Close
          </Button>
        </Box>
      </DialogActions>
    </StyledDialog>
  )
}
