import React, {useEffect} from 'react';
import {
  Button, Box, ListItem, ListItemText, List, ListItemSecondaryAction, ListSubheader, Typography
} from '@material-ui/core';
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import {CreateOutcomeDialog, OutcomeDialog} from "./outcome";
import styled from "styled-components";
import {createOutcome, deleteOutcome, updateOutcome} from "../../../../services/careplan.service";
import {GlobalNotificationContext} from "../../../notification/globalnotificationprovider";


const ListContainer = styled(List)`
    height: 30vh;
    border: 1px solid #ccc;
    overflow: auto;
`


export const CareplanOutcomes = ({careplan, goal, outcomes, onAdd, onRemove, users = []}) => {
  const [viewOutcomeDialog, setViewOutcomeDialog] = React.useState(false);
  const [selectedOutcome, setSelectedOutcome] = React.useState(null);
  const [viewCreateOutcomeDialog, setViewCreateOutcomeDialog] = React.useState(false);
  const [rows, setRows] = React.useState([])
  const { addNotification } = React.useContext(GlobalNotificationContext);

  useEffect(() => {
    setRows(goal?.outcomes ?? [])
    // eslint-disable-next-line
  }, [outcomes])

  const onView = (outcome) => {
    setSelectedOutcome(outcome)
    setViewOutcomeDialog(true);
  }

  const onDelete = async (outcome) => {
    const deleteResponse = await deleteOutcome(
      careplan?.resident?._id ?? careplan?.resident,
      careplan?._id,
      goal?._id,
      outcome?._id);
    if (deleteResponse?.error) {
      addNotification("Unable to delete outcome", "error");
    } else {
      addNotification(
        `Outcome ${outcome?.description} deleted`, "success",
      )
      setRows(rows.filter(row => row._id !== outcome?._id));
      onRemove(outcome);
      setViewOutcomeDialog(false);
    }
  }

  const onCreate = async (outcome) => {
    const newOutcome = await createOutcome(
      careplan?.resident?._id ?? careplan?.resident,
      careplan?._id,
      goal?._id,
      outcome);
    if (!newOutcome?.error) {
      setViewCreateOutcomeDialog(false);
      setRows([...rows, newOutcome]);
      onAdd(newOutcome)
    } else {
      addNotification("Unable to create outcome", "error");
    }
  }

  const handleUpdate = async (outcome) => {
    const updateOutcomeResponse = await updateOutcome(careplan?.resident?._id ?? careplan?.resident,
      careplan?._id,
      goal?._id,
      outcome?._id,
      outcome);
    if (updateOutcomeResponse?.error) {
      addNotification("Unable to update outcome", "error");
    } else {
      const updated = rows.map(row => row._id === updateOutcomeResponse._id ? updateOutcomeResponse : row);
      setRows(updated);
      setViewOutcomeDialog(false);
    }
  }

  const getSecondaryText = (outcome) => {
    return (
        <Typography variant={"caption"}>
          {moment(outcome.measuredAt).format("MM/DD/YYYY")} | {outcome.status}
        </Typography>
    )
  }


  return (
      <div>
        <CreateOutcomeDialog open={viewCreateOutcomeDialog}
                             onClose={() => setViewCreateOutcomeDialog(false)}
                             onCreate={onCreate}
                             users={users}
        />
        <OutcomeDialog outcome={selectedOutcome}
                       open={viewOutcomeDialog}
                       onSave={handleUpdate}
                       onDelete={onDelete}
                       onClose={() => setViewOutcomeDialog(false)}
                       users={users}
        />
        <ListSubheader disableSticky>
          <Box display={"flex"} justifyContent={"space-between"}>
            <span>Outcomes</span>
            {rows?.length > 0 &&
              <Button size={"small"}
                    startIcon={<AddIcon />}
                    onClick={() => setViewCreateOutcomeDialog(true)}
                    >
              Add Outcome
            </Button>
            }
          </Box>
        </ListSubheader>
        <ListContainer dense component={"nav"}>
          {rows?.length > 0 ?
            rows.map((outcome, index) =>
            <ListItem dense={true} button onClick={() => onView(outcome)} key={index}>
              <ListItemText primary={outcome.description}
                            secondary={getSecondaryText(outcome)}
              />
            </ListItem>
            ):
            <ListItem dense={true}>
              <ListItemText primary={"No outcomes available."} />
              <ListItemSecondaryAction>
                <Button startIcon={<AddIcon />}
                        onClick={() => setViewCreateOutcomeDialog(true)}
                        size={"small"}>Add Outcome</Button>
              </ListItemSecondaryAction>
            </ListItem>
          }
        </ListContainer>
      </div>
  )
}
