import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../../../../utils/forms";
import {OutcomeModel} from "./OutcomeModel";
import {ObjectId} from "bson";


export const CreateOutcomeModal = ({open, onClose, onSave, users}) => {
  const [model, setModel] = React.useState((JSON.parse(JSON.stringify(OutcomeModel))));

  React.useEffect(() => {
    if (open && users) {
      const obj = JSON.parse(JSON.stringify(OutcomeModel));
      obj._id.value = new ObjectId();
      obj.user.dropDownItems = users;
      setModel(obj);
    }
  }, [open, users]);

  const save = () => {
    let data = {};
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    })
    onSave(data);
  }

  const onChange = e => {
    setModel({...model, ...e});
  }

  return (
    <Dialog open={open}
            onClose={onClose}
            fullWidth
            maxWidth={"md"}
    >
      <DialogTitle>{"Create OutcomeDialog"}</DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator model={model} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button color={"primary"}
                variant={"text"}
                onClick={onClose}>
          Cancel
        </Button>
        <Button color={"primary"}
                variant={"contained"}
                disabled={!isModelValid(model)}
                onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
