export const CareplanModel = {
  name: {
    title: 'Care Plan Name',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  planType: {
    title: 'Careplan Type',
    required: true,
    value: null,
    dirty: false,
    isValid: false,
  },
  status: {
    title: 'Status',
    required: true,
    value: null,
    dirty: false,
    isValid: false,
    dropDown: true,
    dropDownItems: [{
      value: 'active',
      name: 'Active',
    }, {
      value: 'inactive',
      name: 'Inactive',
    }, {
      value: 'completed',
      name: 'Completed',
    }],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  problemArea: {
    title: 'Problem Area',
    required: true,
    value: null,
    dirty: false,
    isValid: true,
    multiSelect: false,
    dropDown: false,
    dropDownItems: [],
    dropDownItemValue: 'value',
    dropDownItemLabel: 'name',
    date: false,
  },
  careplanDate: {
    title: 'Care Plan Date',
    required: true,
    value: null,
    isValid: true,
    dropDown: false,
    date: true,
  },
  reviewDate: {
    title: 'Review Date',
    required: false,
    value: null,
    isValid: true,
    dropDown: false,
    date: true,
  },
  discontinuedDate: {
    title: 'Discontinued Date',
    required: false,
    value: null,
    isValid: true,
    dropDown: false,
    date: true,
  },
  tags: {
    isTagsComponent: true,
    title: 'Tags',
    value: [],
    required: false,
  },
  goals: {
    title: 'Goals',
    hidden: true,
    value: [],
    required: false,
  },
};
