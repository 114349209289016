import React, {useContext, useEffect} from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
import {Link as RouterLink} from 'react-router-dom'
import {AddGoalModal} from "./add";
import {AddInterventionModal} from "../interventions/add";
import {
  createGoal,
  createIntervention,
  deleteGoal, deleteIntervention,
  updateGoal, updateIntervention,
} from "../../../../services/careplan.service";
import styled from "styled-components";
import {EditGoalModal} from "./edit";
import ConfirmDialog from "../../../utils/dialogs/confirm.dialog";
import {UserContext} from "../../../../context/usercontext";
import {getTenantUsers} from "../../../../services/tenant.service";
import {ResidentContext} from "../../../../context/residentcontext";
import {InterventionDialog} from "../interventions/intervention";
import useGlobalNotification from "../../../../hooks/notification.hook";


const StyledLink = styled(Link)`
    cursor: pointer;
`

const BorderLessTableCell = styled(TableCell)`
    border-bottom: none;
`

export const GoalsAndInterventions = ({careplan}) => {
  const [currentCareplan, setCurrentCareplan] = React.useState(careplan);
  const [loadingIntervention, setLoadingIntervention] = React.useState(false);
  const [selectedGoal, setSelectedGoal] = React.useState(null);
  const [selectedIntervention, setSelectedIntervention] = React.useState(null);
  const [viewAddGoalModal, setViewAddGoalModal] = React.useState(false);
  const [viewAddInterventionModal, setViewAddInterventionModal] = React.useState(false);
  const [viewEditGoalModal, setViewEditGoalModal] = React.useState(false);
  const [viewDeleteGoalConfirmationDialog, setViewDeleteGoalConfirmationDialog] = React.useState(false);
  const [viewInterventionModal, setViewInterventionModal] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const { selectedTenant } = useContext(UserContext);
  const { addNotification } = useGlobalNotification();
  const { resident } = React.useContext(ResidentContext);

  useEffect(() => {
    setCurrentCareplan(careplan);
  }, [careplan]);

  useEffect(() => {
    if (selectedTenant?._id) {
      getTenantUsers(selectedTenant?._id).then(users => setUsers(users))
    }
  }, [selectedTenant?._id]);

  const handleAddGoal = () => {
    setViewAddGoalModal(true);
  }

  const saveGoal = async goal => {
    const addGoalResponse = await createGoal(careplan?.resident, careplan?._id, goal);
    if (!addGoalResponse?.error) {
      setCurrentCareplan({
        ...currentCareplan,
        goals: [...currentCareplan?.goals, addGoalResponse]
      })
      setViewAddGoalModal(false);
      addNotification("Goal added successfully", "success");
    }
  }

  const saveIntervention = async intervention => {
    const addInterventionResponse = await createIntervention(careplan?.resident, careplan?._id, selectedGoal?._id, intervention);
    if (!addInterventionResponse?.error) {
      const updatedInterventions = [...selectedGoal?.interventions ?? [], addInterventionResponse];
      const updatedGoal = {
        ...selectedGoal,
        interventions: updatedInterventions
      };
      const updatedGoals = careplan?.goals?.map(goal => goal._id === updatedGoal._id ? updatedGoal : goal);
      careplan.goals = updatedGoals;
      setSelectedGoal(null);
    }
    setViewAddInterventionModal(false);
  }

  const handleEditGoalClick = goal => {
    setSelectedGoal(goal);
    setViewEditGoalModal(true);
  }

  const handleDeleteGoal = async goal => {
    setSelectedGoal(goal);
    setViewDeleteGoalConfirmationDialog(true);
  }

  const handleUpdateGoal = async goal => {
    const updateGoalResponse = await updateGoal(careplan?.resident, careplan?._id, goal?._id, goal);
    if (!updateGoalResponse?.error) {
      currentCareplan.goals = currentCareplan?.goals?.map(g => {
        if (g._id === updateGoalResponse?._id) {
          g.name = updateGoalResponse?.name;
          g.description = updateGoalResponse?.description;
          g.startDate = updateGoalResponse?.startDate;
          g.targetDate = updateGoalResponse?.targetDate;
          g.isActive = updateGoalResponse?.isActive;
          g.status = updateGoalResponse?.status;

          console.log(updateGoalResponse)
        }
        return g;
      });
      setViewEditGoalModal(false);
      setCurrentCareplan(currentCareplan);
      addNotification("Goal updated successfully", "success");
    } else {
      addNotification("error", "Error", "Error updating goal");
    }
  }

  const onDeleteGoalConfirmed = async () => {
    if (selectedGoal) {
      const deleteGoalResponse = await deleteGoal(careplan?.resident, careplan?._id, selectedGoal?._id);
      if (!deleteGoalResponse?.error) {

        setCurrentCareplan({
          ...currentCareplan,
          goals: currentCareplan?.goals?.filter(g => g._id !== selectedGoal?._id)
        })
        setSelectedGoal(null);
        setViewDeleteGoalConfirmationDialog(false);
        setViewEditGoalModal(false);
        addNotification("Goal deleted successfully", "success");
      } else {
        addNotification("error", "Error", "Error deleting goal");
      }
    }
  }

  const getFrequencyLabel = (frequency) => {
    if (frequency?._id) {
      return `${frequency.description} (${frequency.name})`;
    } else {
      return null;
    }
  }

  const handleSaveIntervention = async (interventionId, payload) => {
    const updateInterventionResponse =
      await updateIntervention(careplan?.resident, careplan?._id, selectedGoal?._id, interventionId, payload);

    if (updateInterventionResponse?.error) {
      addNotification("Error updating intervention", "error");
      return;
    }

    const { intervention, eventsCreated } = updateInterventionResponse;

    setCurrentCareplan({
      ...currentCareplan,
      goals: currentCareplan?.goals?.map(goal => {
        if (goal._id === selectedGoal?._id) {
          goal.interventions = goal?.interventions?.map(i => {
            if (i?._id === intervention?._id) {
              return intervention;
            } else {
              return i;
            }
          });
        }
        return goal;
      })
    })
    setViewInterventionModal(false);
    addNotification(`Intervention updated successfully, ${eventsCreated} events created.`, "success");
  }

  const handleInterventionClick = (intervention, goal) => {
    setSelectedGoal(goal);
    setSelectedIntervention(intervention);
    setViewInterventionModal(true);
  }

  const handleOnAddIntervention = (goal) => {
    setSelectedGoal(goal);
    setViewAddInterventionModal(true);
  }

  const handleDeleteIntervention = async (interventionId) => {
    setLoadingIntervention(true);
    const deleteInterventionResponse = await deleteIntervention(
      careplan?.resident,
      careplan?._id,
      selectedGoal?._id,
      interventionId
    );
    if (!deleteInterventionResponse?.error) {
      setCurrentCareplan({
        ...currentCareplan,
        goals: currentCareplan?.goals?.map(goal => {
          if (goal._id === selectedGoal?._id) {
            goal.interventions = goal?.interventions?.filter(i => i?._id !== interventionId);
          }
          return goal;
        })
      });
      setViewDeleteGoalConfirmationDialog(false);
      setViewInterventionModal(false);
      addNotification("Intervention deleted successfully", "success");
    }
    setLoadingIntervention(false);
  }

  return (
    <TableContainer>
      <AddGoalModal isOpen={viewAddGoalModal}
                    onSave={saveGoal}
                    onClose={() => setViewAddGoalModal(false)}
      />
      <EditGoalModal isOpen={viewEditGoalModal}
                     onSave={handleUpdateGoal}
                     onClose={() => setViewEditGoalModal(false)}
                     onDelete={handleDeleteGoal}
                     goal={selectedGoal}
                     users={users}
                     careplan={careplan}
      />
      <ConfirmDialog title={"Delete Goal"}
                     open={viewDeleteGoalConfirmationDialog}
                     message={"Do you want to delete this goal? All related outcomes, interventions and events will be deleted."}
                     onClose={() => setViewDeleteGoalConfirmationDialog(false)}
                     onConfirm={onDeleteGoalConfirmed} />
      <AddInterventionModal isOpen={viewAddInterventionModal}
                            goal={selectedGoal}
                            onClose={() => setViewAddInterventionModal(false)}
                            onSave={saveIntervention}
      />
      <InterventionDialog goal={selectedGoal}
                          loading={loadingIntervention}
                          careplan={currentCareplan}
                          intervention={selectedIntervention}
                          open={viewInterventionModal}
                          onSave={handleSaveIntervention}
                          onClose={() => setViewInterventionModal(false)}
                          onDelete={handleDeleteIntervention}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell colSpan={5} width={10}>
              <Button size={"small"}
                      startIcon={<AddIcon fontSize={"small"} />}
                      variant={"outlined"}
                      onClick={handleAddGoal}
              >
                Add Goal
              </Button>
            </TableCell>
            <TableCell colSpan={2} width={10} align={"right"}>
              <Button size={"small"}
                      variant={"outlined"}
                      component={RouterLink}
                      to={`/residents/${resident?._id}/tasks`}
              >
                eTask
              </Button>
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell width={450}>
              Goal Name
            </TableCell>
            <TableCell width={100}>
              Date
            </TableCell>
            <TableCell>
              Target Date
            </TableCell>
            <TableCell>
              Status
            </TableCell>
            <TableCell colSpan={4}>
              Interventions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentCareplan?.goals?.map((goal, index) =>
            <TableRow>
              <TableCell>
                <StyledLink underline={"always"} onClick={() => handleEditGoalClick(goal)}>
                  {goal?.name}
                </StyledLink>
              </TableCell>
              <TableCell>
                {moment(goal?.startDate).format('MM/DD/YYYY')}
              </TableCell>
              <TableCell>
                {moment(goal?.targetDate).format('MM/DD/YYYY')}
              </TableCell>
              <TableCell>
                {goal?.status}
              </TableCell>
              <TableCell colSpan={4}>
                <Table size="small">
                  <TableRow>
                    <BorderLessTableCell padding={"none"}>
                      <Button size={"small"}
                              startIcon={<AddIcon fontSize={"small"} />}
                              variant={"outlined"}
                              onClick={() => handleOnAddIntervention(goal)}
                      >
                        Add Intervention
                      </Button>
                    </BorderLessTableCell>
                    <BorderLessTableCell width={150}>
                      Start Date
                    </BorderLessTableCell>
                    <BorderLessTableCell width={150}>
                      End Date
                    </BorderLessTableCell>
                    <BorderLessTableCell width={175}>
                      Frequency
                    </BorderLessTableCell>
                  </TableRow>
                  {goal?.interventions?.map((intervention, i) =>
                    <TableRow>
                      <BorderLessTableCell padding={"none"}>
                        <StyledLink onClick={() => handleInterventionClick(intervention, goal)}>
                          {intervention?.name}
                        </StyledLink>
                      </BorderLessTableCell>
                      <BorderLessTableCell>
                        {moment(intervention.startDate).format('MM/DD/YYYY')}
                      </BorderLessTableCell>
                      <BorderLessTableCell>
                        {moment(intervention.endDate).format('MM/DD/YYYY')}
                      </BorderLessTableCell>
                      <BorderLessTableCell>
                        {getFrequencyLabel(intervention?.frequency)}
                      </BorderLessTableCell>
                    </TableRow>
                  )}
                </Table>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )

}
