import React from 'react';
import {Box, Button, ButtonGroup, Paper, TextField} from "@material-ui/core";
import moment from "moment";
import ConfirmDialog from "../../../utils/dialogs/confirm.dialog";
import DeleteIcon from "@material-ui/icons/Delete";

export const CareplanDashboard = ({careplan, onDelete}) => {
  const [viewConfirmDelete, setViewConfirmDelete] = React.useState(false);

  const onDeleteConfirmed = () => {
    onDelete(careplan);
    setViewConfirmDelete(false);
  }

  return (
    <Paper elevation={3}>
      <ConfirmDialog open={viewConfirmDelete}
                     onConfirm={onDeleteConfirmed}
                     onClose={() => setViewConfirmDelete(false)}
                     description={"Are you sure you want to delete this care plan?"}
                     title={"Delete Care Plan"}
                     confirmText={"Delete"}
      />

        <Box p={1}
             display={"flex"}
             justifyContent={"space-between"}
             width={"100%"}>
          <TextField label={"Care Plan Name"}
                     underline={"none"}
                     InputLabelProps={{shrink: true}}
                     InputProps={{readOnly: true, disableUnderline: true }}
                     value={careplan?.name} />
          <TextField label={"Care Plan Status"}
                     InputLabelProps={{shrink: true}}
                     InputProps={{readOnly: true, disableUnderline: true }}
                     value={careplan?.status}
          />
          <TextField label={"Problem Area"}
                     InputLabelProps={{shrink: true}}
                     InputProps={{readOnly: true, disableUnderline: true }}
                     value={careplan?.problemArea}
          />
          <TextField label={"Care Plan Type"}
                     InputLabelProps={{shrink: true}}
                     InputProps={{readOnly: true, disableUnderline: true }}
                     value={careplan?.planType}
          />
          <TextField label={"Careplan Date"}
                     InputLabelProps={{shrink: true}}
                     InputProps={{readOnly: true, disableUnderline: true }}
                     value={careplan?.careplanDate ? moment(careplan?.careplanDate).format("MM/DD/YYYY"): null}
          />
          <TextField label={"Discontinued Date"}
                     InputLabelProps={{shrink: true}}
                     InputProps={{readOnly: true, disableUnderline: true }}
                     value={careplan?.discontinuedDate ? moment(careplan?.discontinuedDate).format("MM/DD/YYYY") : null}
          />
          <TextField label={"Review Date"}
                     InputLabelProps={{shrink: true}}
                     InputProps={{readOnly: true, disableUnderline: true }}
                     value={careplan?.reviewDate ? moment(careplan?.reviewDate).format("MM/DD/YYYY"): null}
          />
          <ButtonGroup>
            <Button onClick={() => setViewConfirmDelete(true)}
                    startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
            <Button>Resolve</Button>
            <Button>Review</Button>
          </ButtonGroup>
        </Box>
    </Paper>
    )
}
