import React from 'react';
import {
  CareplanDashboard,
  CareplanOverview, GoalsAndInterventions
} from "../../../components/resident/care_plan";
import {AppBar, Box, LinearProgress, Tab, Tabs} from "@material-ui/core";
import {PageTitle} from "../../../components/utils";
import {useParams, useLocation, useHistory} from "react-router-dom";
import {deleteResidenCareplan, getResidentCareplan, updateResidenCareplan} from "../../../services/careplan.service";
import useGlobalNotification from "../../../hooks/notification.hook";

export const ResidentCareplanPage = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const [careplan, setCareplan] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const {careplanId, residentId} = useParams();
  const { addNotification } = useGlobalNotification();

  const getTabFromUrl = (location) => {
    const params = new URLSearchParams(location.search);
    const tab = params.get('tab');
    return tab !== null ? parseInt(tab, 10) : null;
  };

  React.useEffect(() => {
    const tab = getTabFromUrl(location);
    if (tab !== null && !isNaN(tab)) {
      setTabValue(tab);
    }
  }, [location, location.search]);


  React.useEffect(() => {
    getData(residentId, careplanId).then(data => {});
  }, [residentId, careplanId]);

  const getData = async (residentId, careplanId) => {
    setLoading(true);
    const response = await getResidentCareplan(residentId, careplanId);
    if (!response?.error) {
      setCareplan(response);
    }
    setLoading(false);
  }

   const handleOverViewSave = async (data) => {
     setLoading(true);
     const response = await updateResidenCareplan(residentId, careplanId, data);
     if (!response?.error) {
       setCareplan(response);
       addNotification("Care Plan Updated", "success");
     } else{
       addNotification("Error Updating Care Plan", "error");
     }
     setLoading(false);
   }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue); // Update state
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', newValue); // Update query parameter
    history.push({ search: searchParams.toString() }); // Modify URL without refreshing
  };

  const handleDelete = async (careplan) => {
    const deleteResponse = await deleteResidenCareplan(residentId, careplan?._id);
    if (!deleteResponse?.error) {
      addNotification("Care Plan Deleted", "success");
      history.push(`/residents/${residentId}/careplans`);
    } else {
      addNotification("Error Deleting Care Plan", "error");
    }
  }

    return (
      <Box p={1}>
        <div style={{position: "sticky"}}>
          <PageTitle title={"Care Plan"} />
          <CareplanDashboard careplan={careplan} onDelete={handleDelete} />
          <AppBar position="static" elevation={1} >
            <Tabs
              indicatorColor="primary"
              variant="fullWidth"
              value={tabValue}
              onChange={handleTabChange}
            >
              <Tab label={"Overview"} />
              <Tab label={"Goals & Interventions"} />
              <Tab label={"Audits"} />
            </Tabs>
          </AppBar>
        </div>
        <LinearProgress style={{visibility: loading ? "visible" : "hidden"}} />
        {tabValue === 0 && <CareplanOverview careplan={careplan} onSave={handleOverViewSave} />}
        {tabValue === 1 && <GoalsAndInterventions careplan={careplan} />}
      </Box>
    )
}
