import React from 'react';
import {CircularProgress, Typography} from "@material-ui/core";
import styled from "styled-components";
import {useParams} from "react-router-dom";
import history from '../../../utils/history';

const Container = styled.div`
    padding: 2vh;
    text-align: center;
`;


export const ResidentCareplanPageRerouter = () => {
  const { residentId, careplanId, goalId } = useParams();
  React.useEffect(() => {
    if (residentId && careplanId && goalId) {
      history.push(`/residents/${residentId}/careplans/${careplanId}?tab=1&goal=${goalId}`)
    }

  }, [residentId, careplanId, goalId])

  return (
    <Container>
      <CircularProgress size={40} />
      <Typography variant={"h6"}>
        Loading Careplan...
      </Typography>
    </Container>

  )
}
