import React from 'react';
import {
  Box, Button,
  Collapse, Grid, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import {AddGoalModal} from "./add";
import {AddInterventionModal} from "../interventions/add";
import {createGoal, deleteGoal, updateGoal} from "../../../../services/careplan.service";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../../../utils/dialogs/confirm.dialog";
import {EditGoalModal} from "./edit";


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row({row, key, onDelete, onEdit}) {
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);
  const [viewInterventionModal, setViewInterventionModal] = React.useState(false);
  const [viewConfirmDeleteDialog, setViewConfirmDeleteDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const menuOpen =  Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    setViewConfirmDeleteDialog(true);
  }

  const handleConfirmDelete = () => {
    setAnchorEl(null);
    setViewConfirmDeleteDialog(false);
    onDelete(row);
  }

  const viewIntervention = (event, intervention) => {

  };

  const handleUpdateGoal = async (goal) => {
    setIsEditModalOpen(false);
    onEdit(goal);
  }

  const handleEditClick = () => {
    setIsEditModalOpen(true);
    setAnchorEl(null);
  }


  return (
    <React.Fragment>
      <ConfirmDialog open={viewConfirmDeleteDialog}
                     onClose={() => setViewConfirmDeleteDialog(false)}
                     onConfirm={handleConfirmDelete}
                     title="Are you sure you want to delete?"
                     message="Are you sure you want to delete?"
      />
      <EditGoalModal
        goal={row}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={handleUpdateGoal}
      />
      <TableRow className={classes.root}>
        <TableCell width={10}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell width={10}>
          <IconButton size={"small"} onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={menuOpen}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 48 * 4.5,
                width: '20ch',
              },
            }}
          >
            <MenuItem key={1} onClick={handleEditClick}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary={"Edit"} />
            </MenuItem>
            <MenuItem key={1} onClick={handleDeleteClick}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary={"Delete"} />
            </MenuItem>
          </Menu>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.description}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{moment(row?.startDate).format("MM/DD/YYYY")}</TableCell>
        <TableCell>{moment(row?.targetDate).format("MM/DD/YYYY")}</TableCell>
        <TableCell>{row?.isActive ? "Yes": "No"}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} >
              <AddInterventionModal isOpen={viewInterventionModal}
                                    onSave={() => setViewInterventionModal(false)}
                                    onClose={() => setViewInterventionModal(false)}
              />
              <Grid container justifyContent={"flex-start"} alignItems={"center"}>
                <Typography variant="subtitle1" gutterBottom component="div"
                            style={{marginRight: ".25rem", marginBottom: "0"}}>
                  Interventions
                </Typography>
                <Button variant={"text"}
                        onClick={() => setViewInterventionModal(true)}
                        startIcon={<AddIcon />}>
                  Add Inervention
                </Button>
              </Grid>
              <TableContainer>
                <Table  size="small" aria-label="interventions table" component={Paper} elevation={24}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Name</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Frequency</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.interventions?.map((intervention) => (
                      <TableRow key={intervention?._id}>
                        <TableCell>
                          <Button size={"small"}
                                  variant={"text"}
                                  onClick={(event) => viewIntervention(event, intervention)}
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {intervention.name}
                        </TableCell>
                        <TableCell>{moment(intervention.startDate).format("MM/DD/YYYY")}</TableCell>
                        <TableCell>{moment(intervention.endDate).format("MM/DD/YYYY")}</TableCell>
                        <TableCell>{intervention?.frequency?.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export const CareplanGoalsTable = ({careplan, onGoalAdd, onGoalUpdated, onGoalDelete}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [goals, setGoals] = React.useState([]);

  React.useState(() => {
    setGoals(careplan?.goals);
  }, []);

  const handleAddGoal = async (goal) => {
    const createGoalResponse = await createGoal(careplan?.resident, careplan?._id, goal);
    if (!createGoalResponse?.error) {
      setGoals([...goals, goal]);
    }
    setIsModalOpen(false)
    onGoalAdd(goal);
  };

  const handleGoalEdit = async (goal) => {
    const editGoalResponse = await updateGoal(careplan?.resident, careplan?._id, goal?._id, goal);
    if (!editGoalResponse?.error) {

      setGoals(prev => prev?.map(g => g?._id === goal?._id ? goal: g))
      onGoalUpdated(goal);
    }
  }

  const handleGoalDelete = async deletedGoal => {
    const deleteGoalResponse = await deleteGoal(careplan?.resident, careplan?._id, deletedGoal?._id);
    if (!deleteGoalResponse?.error) {
      setGoals(pre => pre?.filter(p => p?._id !== deletedGoal?._id));
      onGoalDelete(deletedGoal);
    }
  }

  return (
    <div>
      <Box display="flex" justifyContent="flex-start" p={1} mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalOpen(true)}
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell>Goal</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>Target Date</TableCell>
              <TableCell>Active</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {(goals ?? []).map((row) => (
              <Row key={row.name} row={row} onDelete={handleGoalDelete} onEdit={handleGoalEdit} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AddGoalModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleAddGoal}
      />
    </div>
  )
}
