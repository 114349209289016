import React, { useState } from "react";
import {
  Dialog,
  Backdrop,
  Button,
  Box, DialogContent, DialogActions, DialogTitle,
} from "@material-ui/core";
import moment from "moment";
import {FormFieldsGenerator, isModelValid} from "../../../../../utils/forms";

const STATUS_ENUM = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
};

// Initial Form Model
const getInitialFormModel = () => ({
  name: {
    title: "Name",
    value: "",
    required: true,
    isValid: true,
    textType: "text",
  },
  description: {
    title: "Description",
    value: "",
    required: false,
    multiline: true,
    multilineRows: 3,
  },
  startDate: {
    title: "Start Date",
    value: moment().format("YYYY-MM-DD"),
    required: true,
    date: true,
  },
  targetDate: {
    title: "Target Date",
    value: moment().add(7, "days").format("YYYY-MM-DD"),
    required: true,
    date: true,
  },
  status: {
    title: "Status",
    value: STATUS_ENUM.NOT_STARTED,
    dropDown: true,
    dropDownItems: Object.values(STATUS_ENUM).map((status) => ({
      label: status,
      value: status,
    })),
    dropDownItemValue: "value",
    dropDownItemLabel: "label",
    required: true,
  },
  isActive: {
    title: "Is Active",
    value: true,
    isToggle: true,
    required: false,
    hidden: true,
  },
});

export const AddGoalModal = ({ isOpen, onClose, onSave }) => {
  const [formModel, setFormModel] = useState(getInitialFormModel);

  const handleFormChange = (updatedModel, changedKey) => {
    setFormModel(updatedModel);
  };

  // Reset the form model each time the modal is opened
  React.useEffect(() => {
    if (isOpen) {
      setFormModel(getInitialFormModel());
    }
  }, [isOpen]);

  const handleSubmit = () => {
    const data =
    Object.keys(formModel).reduce((acc, key) => {
      acc[key] = formModel[key].value;
      if (key === "startDate" || key === "targetDate") {
        acc[key] = moment(formModel[key].value).toISOString();
      }
      return acc;
    }, {})
    onSave(data)
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>
        Create Goal
      </DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator model={formModel} onChange={handleFormChange} />
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="text"
            onClick={onClose}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!isModelValid(formModel)}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
