import React, { useState } from "react";
import {
  Dialog,
  Backdrop,
  Button,
  Box,
  DialogContent,
  DialogActions,
  DialogTitle,
  TableContainer,
  Table,
  TableBody,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import moment from "moment";
import {FormFieldsGenerator, isModelValid} from "../../../../../utils/forms";
import {InterventionModel} from "../intervention.model"
import BaseEvent from "../../../../medicationorders/create/base.event";
import {getFrequencies} from "../../../../../services/frequency.service";
import {ObjectId} from "bson";
import {getTenantLocationRoles} from "../../../../../services/tenant.service";
import {UserContext} from "../../../../../context/usercontext";
import {ResidentContext} from "../../../../../context/residentcontext";
import styled from "styled-components";


const LoadingDialogContent = styled(DialogContent)`
    padding: 0;
`

const LoadingIndicatorContent = styled.div`
    min-height: 25vh;
    text-align: center;
    padding: 5rem;
`



export const AddInterventionModal = ({ isOpen, onClose, onSave }) => {
  const {selectedTenant} = React.useContext(UserContext);
  const { resident } = React.useContext(ResidentContext);
  const [formModel, setFormModel] = useState(JSON.parse(JSON.stringify(InterventionModel)));
  const [frequencies, setFrequencies] = useState([])
  const [frequency, setFrequency] = React.useState(null);
  const [loading, setLoading] = useState(true);

  // Reset the form model each time the modal is opened
  React.useEffect(() => {
    if (isOpen) {
      setupModel().catch(e => console.log(e));
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const setupModel = async () => {
    setLoading(true);
    const tenantLocationRoles = await getTenantLocationRoles(selectedTenant?._id)
    let mappedRoles = tenantLocationRoles?.filter(tl => tl?.location?._id === resident?.location?._id)
        ?.map(tl => (
            {...tl,
              name: `${tl?.role?.name} - ${tl?.location?.name}`,
              value: `${tl?._id}`
            })).sort((a, b) => a?.name > b?.name ? 1: -1);
    getFrequencies().then(res => {
      setFrequencies(res);
      const obj = JSON.parse(JSON.stringify(InterventionModel));
      obj.frequency.dropDownItems = res;
      const onceADayFrequency = res?.find(f => f.name === "QD")
      obj.frequency.value = onceADayFrequency?._id;
      obj.eventTemplates.value = processDay(onceADayFrequency?._id, res);
      obj.discipline.dropDownItems = mappedRoles
      setFrequency(onceADayFrequency);
      setFormModel(obj);
      setLoading(false);
    });
  }

  const processDay = (frequencyId, freqs) => {
    let eventTemplates = [];
    if (freqs) {
      const freq = freqs?.find((f) => f._id === frequencyId);
      if (freq) {
        setFrequency(freq);
        const defaultHours = {
          1: { 1: 8 },
          2: { 1: 8, 2: 16 },
          3: { 1: 8, 2: 12, 3: 16 },
          4: { 1: 8, 2: 10, 3: 14, 4: 16 },
          5: { 1: 8, 2: 10, 3: 12, 4: 14, 5: 16 },
        };

        for (let i = 1; i <= freq.frequencyEventCount; i++) {
          const d = new Date();
          // Auto increment hours
          let calculatedHour = defaultHours[freq.frequencyEventCount][i];
          const initStartDate = d.setHours(calculatedHour, 0, 0, 0);
          const hour = moment(initStartDate).format('H');
          const minute = moment(initStartDate).format('mm');
          eventTemplates.push({
            _id: new ObjectId(),
            sort: i,
            passingQty: 1,
            hour,
            minute,
          });
        }
        const obj = { ...formModel };
        obj.eventTemplates.value = eventTemplates;
        setFormModel(obj);
      }
    }
    return eventTemplates;
  };

  const handleSubmit = () => {
    setLoading(true);
    const data =
      Object.keys(formModel).reduce((acc, key) => {
        acc[key] = formModel[key].value;
        if (key === "startDate" || key === "targetDate") {
          acc[key] = moment(formModel[key].value).toISOString();
        }
        return acc;
      }, {})
    onSave(data)
  };

  const onEventChange = (e, field) => {
    const obj = {...formModel, ...e};
    if (field === "endDate") {
      obj.startDate.maxDateValue = e.endDate.value;
    }
    if (field === "frequency") {
      obj.eventTemplates.value = processDay(obj.frequency.value, frequencies);
    }
    setFormModel(obj);
  }

  return (
    <Dialog
      style={{height: "90vh"}}
      open={isOpen}
      fullWidth={true}
      maxWidth={"lg"}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>
        Create Intervention
      </DialogTitle>
      {loading &&
        <LoadingDialogContent style={{padding: 'none'}} dividers>
          <LinearProgress />
          <LoadingIndicatorContent>
            <Typography variant="h6">
              Setting up your intervention form...
            </Typography>
          </LoadingIndicatorContent>
        </LoadingDialogContent>
      }
      {!loading &&
        <DialogContent dividers>
          {JSON.stringify(loading, null, 2)}
          <FormFieldsGenerator model={formModel} onChange={onEventChange} />
          {!formModel?.isPrn?.value && (
            <TableContainer>
              <Table key={"add-intervention-table"}>
                <TableBody>
                  {formModel?.eventTemplates?.value?.map((event) => (
                    <BaseEvent
                      frequency={frequency}
                      eventTemplate={event}
                      onChange={onEventChange}
                      showQty={false}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      }
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button
            variant="text"
            onClick={onClose}
            style={{ marginRight: "8px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || !isModelValid(formModel)}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
