import React from 'react';
import {AppBar, Box, Button, ButtonGroup, LinearProgress, Tab, Tabs} from "@material-ui/core";
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {PageTitle} from "../../../utils";
import { CreateCareplanDashboard } from './CreateCareplanDashboard';
import { CareplanModel } from './Careplan.Model';
import moment from "moment";
import {FormFieldsGenerator, isModelValid} from "../../../../utils/forms";
import {createCareplan} from "../../../../services/careplan.service";

export const CreateCareplan = ({ residentId }) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [model, setModel] = React.useState(structuredClone(CareplanModel));
  const history = useHistory();

  React.useEffect(() => {
    setupModel().catch(console.error);
  }, []);

  const setupModel = async () => {
    const obj = structuredClone(CareplanModel);
    obj.careplanDate.value = moment();
    setModel(obj);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  }

  const handleChange = (updatedModel, field) => {
    setModel({...model, ...updatedModel});
  }

  const handleSave = async () => {
    setLoading(true);
    let data = {};
    Object.keys(model).forEach(key => {
      data[key] = model[key].value
    });
    const createCareplanResponse = await createCareplan(residentId, data);
    if (!createCareplanResponse.error) {
      history.push(`/residents/${residentId}/careplans/${createCareplanResponse._id}`);
    }
  }

  return (
    <Box>
      <div style={{position: "sticky"}}>
        <PageTitle title={"Create Careplan"} />
        <CreateCareplanDashboard model={model}  />
        <AppBar position="static" elevation={1} >
          <Tabs
            indicatorColor="primary"
            variant="fullWidth"
            value={tabValue}
            onChange={handleTabChange}
          >
            <Tab label={"Overview"} />
            <Tab disabled label={"Goals & Interventions"} />
          </Tabs>
        </AppBar>
      </div>
      <LinearProgress style={{visibility: loading ? "visible" : "hidden"}} />
      {tabValue === 0 &&
        <>
          <FormFieldsGenerator model={model}
                               onChange={handleChange}
          />
          <Box p={1} justifyContent={"flex-end"} display={"flex"} width={"100%"}>
            <ButtonGroup>
              <Button component={RouterLink}
                      to={`/residents/${residentId}/careplans`}
                      variant="outlined">
                Cancel
              </Button>
              <Button disabled={!isModelValid(model)}
                      onClick={handleSave}
                      variant="contained"
                      color={"primary"}>
                Save
              </Button>
            </ButtonGroup>
          </Box>
        </>
      }
    </Box>
  )
}
