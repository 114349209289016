import { useContext } from 'react';
import { GlobalNotificationContext } from '../components/notification/globalnotificationprovider';


/**
 * useGlobalNotification is a custom React hook that provides access to the global notification system.
 * It interacts with the context provided by `GlobalNotificationContext` to handle notifications.
 *
 * @returns {Object} Returns an object containing:
 * - `open` (boolean): Indicates whether a notification is open.
 * - `notification` (Object): The current notification details.
 * - `addNotification` (function): A function to add a new notification.
 * - `removeNotification` (function): A function to remove the current notification.
 *
 * @example
 * const { open, notification, addNotification, removeNotification } = useGlobalNotification();
 *
 * if (open) {
 *   console.log('Current Notification:', notification);
 * }
 *
 * addNotification({ message: 'Hello World!', type: 'success' });
 * removeNotification();
 */
function useGlobalNotification() {
  const { open, notification, addNotification, removeNotification } = useContext(GlobalNotificationContext);
  return { open, notification, addNotification, removeNotification };
}

export default useGlobalNotification;
