import React, {useEffect} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton, LinearProgress,
  makeStyles, Table, TableBody, TableContainer, Typography
} from "@material-ui/core";
import {InterventionModel} from './intervention.model';
import CloseIcon from "@material-ui/icons/Close";
import {FormFieldsGenerator, isModelValid} from "../../../../../utils/forms";
import moment from "moment";
import {ObjectId} from "bson";
import {ResidentContext} from "../../../../../context/residentcontext";
import {DeleteBtn} from "../../../../utils/buttons/DeleteBtn";
import SaveIcon from "@material-ui/icons/Save";
import ConfirmDialog from "../../../../utils/dialogs/confirm.dialog";
import BaseEvent from "../../../../medicationorders/create/base.event";
import {getIntervention} from "../../../../../services/careplan.service";
import {getFrequencies} from "../../../../../services/frequency.service";
import {getTenantLocationRoles} from "../../../../../services/tenant.service";
import {UserContext} from "../../../../../context/usercontext";
import styled from "styled-components";
const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const LoadingDialogContent = styled(DialogContent)`
    padding: 0;
`

const LoadingIndicatorContent = styled.div`
    min-height: 25vh;
    text-align: center;
    padding: 5rem;
`

export const InterventionDialog = ({open, onSave, onDelete, onClose, intervention, careplan, goal}) => {
  const classes = useStyles();
  const {selectedTenant} = React.useContext(UserContext);
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(InterventionModel)));
  const { resident } = React.useContext(ResidentContext);
  const [viewConfirmDialog, setViewConfirmDialog] = React.useState(false);
  const [frequency, setFrequency] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (open && intervention) {
      setLoading(true);
      setViewConfirmDialog(false);
      getInterventionData().catch(e => console.log(e));
    }
    // eslint-disable-next-line
  }, [open, intervention]);

  const getInterventionData = async () => {
    const tenantLocationRoles = await getTenantLocationRoles(selectedTenant?._id)
    let mappedRoles = tenantLocationRoles?.filter(tl => tl?.location?._id === resident?.location?._id)
        ?.map(tl => (
            {...tl,
              name: `${tl?.role?.name} - ${tl?.location?.name}`,
              value: `${tl?._id}`
            })).sort((a, b) => a?.name > b?.name ? 1: -1);
    const interventionResponse = await getIntervention(resident?._id, careplan?._id, goal?._id, intervention?._id);
    if (!interventionResponse?.error) {
      const { intervention } = interventionResponse;
      const frequencies = await getFrequencies();
      const obj = JSON.parse(JSON.stringify(InterventionModel));
      Object.keys(intervention).forEach(key => {
        if (obj.hasOwnProperty(key)) {
          if (key === "frequency" || key === "discipline") {
            obj[key].value = intervention[key]?._id;
          } else {
            obj[key].value = intervention[key];
          }
        }
      });
      obj.frequency.dropDownItems = frequencies;
      obj.discipline.dropDownItems = [{ name: 'None', value: '' }, ...mappedRoles];
      setModel(obj);
      // await processDay(obj.frequency.value);
    }
    setLoading(false);
  }

  const onFormChange = (e, field) => {
    const obj = {...model, ...e};
    if (field === "endDate") {
      obj.startDate.maxDateValue = e.endDate.value;
    }
    if (field === 'frequency') {
      processDay(obj.frequency.value);
    }

  }

  const processDay = async (frequencyId) => {
    const frequencies = await getFrequencies();
    if (frequencies) {
      const freq = frequencies.find((f) => f._id === frequencyId);
      if (freq) {
        setFrequency(freq);
        const eventTemplates = [];
        const defaultHours = {
          1: { 1: 8 },
          2: { 1: 8, 2: 16 },
          3: { 1: 8, 2: 12, 3: 16 },
          4: { 1: 8, 2: 10, 3: 14, 4: 16 },
          5: { 1: 8, 2: 10, 3: 12, 4: 14, 5: 16 },
        };

        for (let i = 1; i <= freq.frequencyEventCount; i++) {
          const d = new Date();
          // Auto increment hours
          let calculatedHour = defaultHours[freq.frequencyEventCount][i];
          const initStartDate = d.setHours(calculatedHour, 0, 0, 0);
          const hour = moment(initStartDate).format('H');
          const minute = moment(initStartDate).format('mm');
          eventTemplates.push({
            _id: new ObjectId(),
            sort: i,
            passingQty: 1,
            hour,
            minute,
          });
        }
        const obj = { ...model };
        obj.eventTemplates  = {
          ...obj.eventTemplates,
          value: eventTemplates
        };
        setModel(obj);
      }
    }
  };

  const handleUpdateIntervention = () => {
    setLoading(true);
    let data = {};
    Object.keys(model).forEach(key => {
      data[key] = model[key].value
    });
    onSave(intervention?._id, data);
  }

  const handleConfirmDelete = () => {
    onDelete(intervention?._id)
  }


  const onEventChange = e => {
    const obj = { ...model };
    if (obj.eventTemplates && obj.eventTemplates.value) {
      obj.eventTemplates.value = obj.eventTemplates.value.map((eventTemplate) =>
        eventTemplate._id === e._id ? e : eventTemplate
      );
     setModel(obj);
    }
  }

  return (
    <Dialog open={open}
            onClose={onClose}
            fullWidth={true}
            maxWidth={'lg'}>
      <DialogTitle>
        Intervention: {intervention?.name}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {loading &&
        <LoadingDialogContent style={{padding: 'none'}} dividers>
          <LinearProgress />
          <LoadingIndicatorContent>
            <Typography variant="h6">
              Working on it...
            </Typography>
          </LoadingIndicatorContent>
        </LoadingDialogContent>
      }
      {!loading &&
      <DialogContent dividers>
        <FormFieldsGenerator model={model}
                             onChange={onFormChange}
        />
        {!model?.isPrn?.value && (
          <TableContainer>
            <Table key={"events-table"}>
              <TableBody>
                {model?.eventTemplates?.value?.map((event) => (
                  <BaseEvent
                    frequency={frequency}
                    eventTemplate={event}
                    onChange={onEventChange}
                    showQty={false}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      }
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <DeleteBtn onClick={() => setViewConfirmDialog(true)} />
          </Grid>
          <Grid item>
            <Button onClick={onClose}>Close</Button>
            <Button disabled={loading || !isModelValid(model)}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleUpdateIntervention}
                    startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <ConfirmDialog open={viewConfirmDialog}
                     title={"Delete Intervention"}
                     message={"Do you want to delete this intervention?"}
                     onClose={() => setViewConfirmDialog(false)}
                     onConfirm={handleConfirmDelete}
      />
    </Dialog>
  )
}
