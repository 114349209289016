import React from 'react';
import {Box, Button, FormGroup, Grid, makeStyles, TextField} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import useHasRole from "../../../../hooks/user.role.hook";
import SaveIcon from "@material-ui/icons/SaveTwoTone";

const useStyles = makeStyles(theme => ({
  formGroup: {
    flex: 1,
    padding: theme.spacing(1),
  },
  datePicker: {
    label: {
      "&.MuiFormLabel-root.Mui-disabled": {
        color: "red"
      },
    },
    "& .MuiInputBase-input .Mui-disabled": {
      border: "1px solid red"
    }
  }
}))

export const CareplanOverview = ({careplan, onSave}) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});
  const {checkPermission} = useHasRole();
  const canUpdate = checkPermission("CAREPLAN_ORDER_UPDATE");

  React.useEffect(() => {
    setModel(careplan);
  }, [careplan]);

  const handleChange = (event) => {
    const {name, value} = event.target;
    setModel({...model, [name]: value});
  }

  const addTag = (tag) => {
    setModel({...model, tags: [...model?.tags, tag]});
  }

  const removeTag = (tag, index) => {
    setModel({...model, tags: model?.tags.filter((t, i) => i !== index)});
  }

  const onDateChange = (date, key) => {
    setModel({...model, [key]: date});
  }

  const isDirty = () => {
    return model !== careplan;
  }

  const canUpdateCareplan = () => {
    return isDirty() && canUpdate;
  }

  const handleCancel = () => {
    setModel(careplan);
  }

  const handleSave = () => {
    onSave(model);
  }

  return (
    <Box p={1}>
      <Grid display={"flex"} justifyContent={"flex-start"} width={"100%"}>
        <FormGroup className={classes.formGroup}>
          <TextField label={"Care Plan Name"}
                     name={"name"}
                     InputLabelProps={{shrink: true}}
                     value={model?.name}
                     onChange={handleChange}
                     disabled={!canUpdate}
          />
          <TextField label={"Care Plan Type"}
                     InputLabelProps={{shrink: true}}
                     value={model?.planType}
                     name={"planType"}
                     onChange={handleChange}
                     disabled={!canUpdate}
          />
          <TextField label={"Care Plan Status"}
                     InputLabelProps={{shrink: true}}
                     value={model?.status}
                     name={"status"}
                     onChange={handleChange}
                     disabled={!canUpdate}
          />
          <TextField label={"Problem Area"}
                     InputLabelProps={{shrink: true}}
                     value={model?.problemArea}
                     name={"problemArea"}
                     onChange={handleChange}
                     disabled={!canUpdate}
          />
        </FormGroup>
        <FormGroup className={classes.formGroup}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              size="small"
              fullWidth
              name={"careplanDate"}
              value={model?.careplanDate}
              label={"Care Plan Date"}
              format="MM/DD/YYYY"
              onChange={(date) => onDateChange(date, "careplanDate")}
              allowKeyboardControl="true"
              ampm={false}
              InputLabelProps={{ shrink: true }}
              disabled={!canUpdate}
            />
            <KeyboardDatePicker
              size="small"
              fullWidth
              name={"discontinuedDate"}
              value={model?.discontinuedDate}
              label={"Discontinued Date"}
              format="MM/DD/YYYY"
              onChange={(date) => onDateChange(date, "discontinuedDate")}
              allowKeyboardControl="true"
              ampm={false}
              InputLabelProps={{ shrink: true }}
              disabled={!canUpdate}
            />
            <KeyboardDatePicker
              className={classes.datePicker}
              size="small"
              fullWidth
              name={"reviewDate"}
              value={model?.reviewDate}
              label={"Review Date"}
              format="MM/DD/YYYY"
              onChange={(date) => onDateChange(date, "discontinuedDate")}
              allowKeyboardControl="true"
              ampm={false}
              InputLabelProps={{ shrink: true }}
              disabled={!canUpdate}
            />
          </MuiPickersUtilsProvider>
          <ChipInput value={model?.tags}
                     onAdd={addTag}
                     label={"Tags"}
                     onDelete={removeTag}
                     blurBehavior={"add"}
                     disabled={!canUpdate}
                     name={"tags"}

          />
        </FormGroup>
        {canUpdate &&
        <Box p={1} display={"flex"} justifyContent={"flex-end"}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant={"contained"}
                  color={"primary"}
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                  disabled={!canUpdateCareplan()}
          >
            Save
          </Button>
        </Box>
        }
      </Grid>
    </Box>
  )
}
