import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Backdrop, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../../../../utils/forms";
import {ChevronLeft} from "@material-ui/icons";
import {OutcomeModel} from "./outcome.model";
import {DeleteBtn} from "../../../../utils/buttons/DeleteBtn";
import ConfirmDialog from "../../../../utils/dialogs/confirm.dialog";


const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    position: absolute;
    top: 15vh;
    height: 65vh;
  }
`;

const mapUsersToDropDownItems = (users = []) =>
  users.map(({ _id, firstName, lastName }) => ({
    _id,
    name: `${firstName ?? ''} ${lastName ?? ''}`.trim(),
  }));



export const OutcomeDialog = ({open, outcome, onClose, onSave, onDelete, users = []}) => {
  const [model, setModel] = useState(JSON.parse(JSON.stringify(OutcomeModel)));
  const [ViewDeleteConfirmationDialog, setViewDeleteConfirmationDialog] = useState(false);

  const handleChange = (form, fieldName) => {
    setModel({...model, ...form})
  }

  useEffect(() => {
    if (open && outcome) {
      const obj = (JSON.parse(JSON.stringify(OutcomeModel)));
      Object.keys(outcome).forEach(key => {
        if (obj.hasOwnProperty(key)) {
          obj[key].value = outcome[key]
        }
      })
      obj.measuredBy.dropDownItems = mapUsersToDropDownItems(users)
      setModel(obj);
    }
    // eslint-disable-next-line
  }, [open, outcome]);

  const handleSave = () => {
    let data = {}
    Object.keys(model).forEach(key => {
      data[key] = model[key].value;
    });
    onSave(data)
  }


  return (
    <StyledDialog
      open={open}
      fullWidth={true}
      maxWidth={"xl"}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <DialogTitle>
        <Button size={"small"} startIcon={<ChevronLeft />} onClick={onClose} /> Outcome
      </DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator onChange={handleChange} model={model} />
      </DialogContent>
      <DialogActions>
        <Box display={"flex"} justifyContent={"space-between"} style={{width: "100%"}}>
          <Box flex={1}>
            <DeleteBtn onClick={() => setViewDeleteConfirmationDialog(true)} />
            <ConfirmDialog onClose={() => setViewDeleteConfirmationDialog(false)}
                           open={ViewDeleteConfirmationDialog}
                           onConfirm={() => onDelete(outcome)}
                           title={"Delete Outcome"}
                           message={"Are you sure you want to delete this outcome?"} />
          </Box>
          <Box justifyContent={"flex-end"}
               display={"flex"} flex={1}>
            <Button
              variant="text"
              style={{ marginRight: "8px" }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!isModelValid(model)}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </StyledDialog>
  )

}
