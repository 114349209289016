import React from 'react';
import {Link as RouterLink, Switch, useParams} from "react-router-dom";
import PrivateRoute from "../../../components/PrivateRoute";
import {ResidentCareplanPage} from "./ResidentCareplanPage";
import {
  Button, Checkbox,
  CircularProgress, FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Table, TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import {PageTitle} from "../../../components/utils";
import AddIcon from "@material-ui/icons/Add";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import useHasRole from "../../../hooks/user.role.hook";
import {ChevronRight} from "@material-ui/icons";
import moment from "moment/moment";
import {createCareplan, getCareplanReports, getResidenCareplans} from "../../../services/careplan.service";
import {ResidentCareplanPageRerouter} from "./ResidentCareplanPageRerouter";
import {ResidentContext} from "../../../context/residentcontext";
import {CreateCareplanDialog} from "../../../components/resident/care_plan/CreateCareplanModal";
import useGlobalNotification from "../../../hooks/notification.hook";
import {CreateCareplanPage} from "./CreateCareplanPage";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  }
}));

const ResidentCareplansPageContent = () => {
  const classes = useStyles();
  const { resident } = React.useContext(ResidentContext);
  const [careplans, setCareplans] = React.useState([]);
  const [generatingPdf] = React.useState(false);
  const { checkPermission } = useHasRole();
  const canPrint = checkPermission("REPORT_PRINTER");
  const [selectAll, setSelectAll] = React.useState(false);
  const [viewCreateCareplanModal, setViewCreateCareplanModal] = React.useState(false);
  const { residentId } = useParams();
  const { addNotification } = useGlobalNotification();

  React.useEffect(() => {
    if (residentId) {
      getCareplans().catch(e => console.log(e))
    }
    // eslint-disable-next-line
  }, [residentId]);

  const getCareplans = async () => {
    const response = await getResidenCareplans(residentId, 'active');
    if (!response?.error) {
      setCareplans(response);
    }
  }

  const careplansSelected = () => {
    return careplans?.filter(c => c.checked)?.length > 0;
  }

  const handleOnCreatedCareplan = async (careplan) => {
    const createCareplanResponse = await createCareplan(residentId, careplan);
    if (!createCareplanResponse?.error) {
      setCareplans([...careplans, createCareplanResponse]);
      setViewCreateCareplanModal(false);
      addNotification("Careplan created", "success");
    } else {
      setViewCreateCareplanModal(false);
      addNotification("Unable to create careplan", "error");
    }
  }

  const printCareplans = async () => {
    const careplanIds = careplans?.filter(c => c.checked)?.map(c => c._id);
    const response = await getCareplanReports(residentId, careplanIds);
    let responseStatus = {error: false};
    if (!response?.error) {
      const link = document.createElement('a');
      const pdf = URL.createObjectURL(response);
      link.href = pdf;
      link.setAttribute(
        'download',
        `${resident?.firstName}-${resident?.lastName}-careplans.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      responseStatus.error = true;
    }
  }

  const toggleChecked = (careplan) => {
    setCareplans(careplans?.map(c => {
      if (c?._id === careplan?._id) {
        c.checked = !c.checked;
      }
      return c;
    }))
  }

  const toggleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    setCareplans(careplans?.map(c => {
      c.checked = e.target.checked;
      return c;
    }));
  }

  return (
    <Paper className={classes.root} elevation={24}>
      <CreateCareplanDialog open={viewCreateCareplanModal}
                            onSave={handleOnCreatedCareplan}
                            onClose={() => setViewCreateCareplanModal(false)}
                            residentId={residentId} />
      <PageTitle title={"Careplans"} />
      <Grid container
            justifyContent={"space-between"}
      >
        <Button variant={"text"}
                startIcon={<AddIcon />}
                // onClick={() => setViewCreateCareplanModal(true);}
                component={RouterLink}
                to={`/residents/${residentId}/careplans/create`}
                color={"primary"}
        >
          Add Careplan
        </Button>
        {careplansSelected() && canPrint &&
          <Button startIcon={generatingPdf ? <CircularProgress color={"#fff"} size={20} />: <PictureAsPdfIcon />}
                  variant={"contained"}
                  onClick={printCareplans}
                  disabled={generatingPdf}
                  color={"primary"}>
            Print {careplans?.filter(c => c?.checked).length} Careplan(s)
          </Button>
        }
      </Grid>
      <TableContainer>
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell width={"20%"}>
                {canPrint &&
                  <FormControlLabel
                    value="bottom"
                    control={<Checkbox color="primary"
                                       checked={selectAll}
                                       onClick={toggleSelectAll} />}
                    labelPlacement="bottom"
                  />
                }
                Careplan
              </TableCell>
              <TableCell width={"25%"}>
                Area
              </TableCell>
              <TableCell width={"25%"}>
                Date
              </TableCell>
              <TableCell width={"25%"}>
                Review Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(careplans ?? [])?.map((careplan, index) =>
              <TableRow>
                <TableCell>
                  <FormControlLabel
                    value="bottom"
                    control={<Checkbox color="primary"
                                       checked={careplan.checked || ''}
                                       onClick={() => toggleChecked(careplan)} />}
                    labelPlacement="bottom"
                  />
                  <Button size={"small"}
                          startIcon={<ChevronRight />}
                          variant={"text"}
                          component={RouterLink}
                          to={`/residents/${residentId}/careplans/${careplan?._id}`}>
                    {careplan?.name}
                  </Button>
                </TableCell>
                <TableCell>
                  {careplan?.problemArea}
                </TableCell>
                <TableCell>
                  {moment(careplan?.careplanDate).format("MM/DD/YYYY")}
                </TableCell>
                <TableCell>
                  {careplan?.reviewDate && moment(careplan?.reviewDate).format("MM/DD/YYYY")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}


export const ResidentCareplansPage = () => {
  return (
        <Switch>
          <PrivateRoute exact path={"/residents/:residentId/careplans"} component={ResidentCareplansPageContent} />
          <PrivateRoute exact path={"/residents/:residentId/careplans/create"} component={CreateCareplanPage} />
          <PrivateRoute exact path={"/residents/:residentId/careplans/:careplanId/"} component={ResidentCareplanPage} />
          <PrivateRoute path={"/residents/:residentId/careplans/:careplanId/goals/:goalId"} component={ResidentCareplanPageRerouter} />
        </Switch>
    )
}
