import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Box, FormControlLabel,
  FormGroup,
  makeStyles, Switch,
  TextField,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MomentUtils from "@date-io/moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import {GoalInterventions} from "../interventions/GoalInterventions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export const Goal = ({goal, expanded, onExpanded}) => {
  const classes = useStyles();
  const [model, setModel] = React.useState({});

  React.useEffect(() => {
    setModel(goal);
  }, [goal]);

  const getGoalSummary = () => {
    return `
    ${goal?.name} ${goal?.startDate ? ` | Start: ${moment(goal?.startDate).format("MM/DD/YYYY")}` : ''}
    ${goal?.targetDate ? ` | Target Date: ${moment(goal?.targetDate).format("MM/DD/YYYY")}` : ''}
    ${goal?.status ? ` | Status: ${goal?.status}` : ''}
    `;
  }

  return (
    <Accordion expanded={expanded === goal._id} onChange={onExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>{getGoalSummary()}</Typography>
        <Typography className={classes.secondaryHeading}>{goal?.description}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Box display="flex" width="100%">
            <Box width="50%" p={2}>
              <FormGroup>
                <TextField label="Name" value={model?.name} />
                <TextField label="Description" value={model?.description} />
                <TextField label="Status" value={model?.status}  />
              </FormGroup>
            </Box>
            <Box width="50%" p={2}>
              <FormGroup>
                <KeyboardDatePicker
                    size="small"
                    fullWidth
                    name={"startDate"}
                    value={model?.startDate}
                    label={"Start Date"}
                    format="MM/DD/YYYY"
                    allowKeyboardControl="true"
                    ampm={false}
                    InputLabelProps={{ shrink: true }}
                    style={{height: '48px'}}
                />
                <KeyboardDatePicker
                    size="small"
                    fullWidth
                    name={"targetDate"}
                    value={model?.targetDate}
                    label={"Target Date"}
                    format="MM/DD/YYYY"
                    allowKeyboardControl="true"
                    ampm={false}
                    InputLabelProps={{ shrink: true }}
                    style={{height: '48px'}}
                />

                <FormControlLabel
                    name={"isActive"}
                    control={
                      <Switch
                          name={"isActive"}
                          color="primary"
                          checked={model?.isActive}
                      />
                    }
                    label={"Active"}
                    labelPlacement="end"
                />
              </FormGroup>
            </Box>
          </Box>
        </MuiPickersUtilsProvider>
      </AccordionDetails>
      <Box p={2} width={"100%"}>
        <GoalInterventions goal={goal} />
      </Box>
    </Accordion>
  )
}

/*

name: { type: String, required: true },
  description: { type: String, default: '' },
  startDate: { type: Date, required: true },
  targetDate: { type: Date, required: true },
  status: { type: String, default: STATUS_ENUM.NOT_STARTED },
  isActive: { type: Boolean, default: true },
 */
