import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles} from "@material-ui/core";
import {FormFieldsGenerator, isModelValid} from "../../../utils/forms";
import CloseIcon from "@material-ui/icons/Close";
import { CareplanModel } from './careplan.model';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  dialogContent: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export const CreateCareplanDialog = ({open, onSave, onClose}) => {
  const classes = useStyles();
  const [model, setModel] = React.useState(JSON.parse(JSON.stringify(CareplanModel)));

  React.useEffect(() => {
    if (open) {
      const obj = JSON.parse(JSON.stringify(CareplanModel));
      obj.careplanDate.value = moment();
      setModel(obj);
    }
    // eslint-disable-next-line
  }, [open]);

  const onChange = e => {
    const obj = { ...model, ...e };
    setModel(obj);
  }

  const handleCreate = async () => {
    let data = {};
    Object.keys(model).forEach(key => {
      data[key] = model[key].value
    });
    await onSave(data);
  }

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={'lg'}
    >
      <DialogTitle>
        Create Careplan
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <FormFieldsGenerator model={model}
                             onChange={onChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleCreate}
                disabled={!isModelValid(model)}
                variant={"contained"} color={"primary"}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}
